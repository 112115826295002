export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [0,10,2,8,3,6];

export const dictionary = {
		"/(public)": [~39,[10]],
		"/(private)/create": [31,[2]],
		"/(private)/create/[apptypeId]": [~32,[2]],
		"/(public)/login": [~40,[10]],
		"/(public)/login/email": [41,[10]],
		"/(public)/login/forgotten-password": [42,[10]],
		"/(public)/login/forgotten-password/password": [43,[10]],
		"/(public)/signup": [44,[10]],
		"/(public)/signup/password": [45,[10]],
		"/(private)/user/settings/general": [33,[2,7]],
		"/(private)/user/settings/git": [34,[2,7]],
		"/(private)/vps/new": [38,[2]],
		"/(private)/vps/[vpsId]": [35,[2,8]],
		"/(private)/vps/[vpsId]/actions": [36,[2,8]],
		"/(private)/vps/[vpsId]/settings/general": [37,[2,8,9]],
		"/(private)/[apptypeId]/[appId]": [11,[2,3]],
		"/(private)/[apptypeId]/[appId]/actions/general": [12,[2,3,4]],
		"/(private)/[apptypeId]/[appId]/database/backups": [~13,[2,3,5]],
		"/(private)/[apptypeId]/[appId]/database/info": [14,[2,3,5]],
		"/(private)/[apptypeId]/[appId]/database/migrations": [15,[2,3,5]],
		"/(private)/[apptypeId]/[appId]/database/whitelist": [16,[2,3,5]],
		"/(private)/[apptypeId]/[appId]/deployments": [17,[2,3]],
		"/(private)/[apptypeId]/[appId]/deployments/[deploymentId]": [18,[2,3]],
		"/(private)/[apptypeId]/[appId]/help": [~19,[2,3]],
		"/(private)/[apptypeId]/[appId]/logs": [20,[2,3]],
		"/(private)/[apptypeId]/[appId]/monitoring": [21,[2,3]],
		"/(private)/[apptypeId]/[appId]/plugins": [22,[2,3]],
		"/(private)/[apptypeId]/[appId]/settings/deployments": [23,[2,3,6]],
		"/(private)/[apptypeId]/[appId]/settings/domains": [24,[2,3,6]],
		"/(private)/[apptypeId]/[appId]/settings/envs": [25,[2,3,6]],
		"/(private)/[apptypeId]/[appId]/settings/general": [26,[2,3,6]],
		"/(private)/[apptypeId]/[appId]/settings/git": [27,[2,3,6]],
		"/(private)/[apptypeId]/[appId]/settings/logs": [28,[2,3,6]],
		"/(private)/[apptypeId]/[appId]/settings/plans": [29,[2,3,6]],
		"/(private)/[apptypeId]/[appId]/storefront": [30,[2,3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';